/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

header {
  background-color: red;
  color: white;
  padding: 20px;
}

header a{
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 25px;

  /* TRANSITION */
  transition-property: all;
  transition-duration: .5s;
}

header a:hover{
  /* text-decoration: underline; */
  font-size: 27px;
}

.main p{
  /* text-align: right; */
  margin-left: 1%;
}

.main h1{
  /* text-align: left; */
  margin-left: 1%;
}

.create-flex{
  display: flex;
  flex-direction: row;
  flex-basis: 10px;
  align-items: center;
  margin-left: 1%;
}

img {
  width: 100px;
  height: 50px;
  object-fit: cover; /* https://stackoverflow.com/questions/16177707/avoid-stretch-on-image-css */
  border: 3px solid black;
}

img:hover{
  cursor: pointer;
}
